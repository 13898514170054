import Vue from 'vue'
import ToastificationContent from '@/components/common/ToastificationContent.vue'
import MemberPermissions from '../enums/memberPermissionsEnum'
import PackageFeatures from '../enums/packagesFeatureTypeEnum'

const getFormDataFromJSON = object => {
  const formData = new FormData()
  Object.keys(object).forEach(key => {
    formData.append(key, object[key])
  })
  return formData
}

const packagePrice = pack => {
  let totalCost = 0
  pack.plan_has_feature.forEach(planHasFeature => {
    if (planHasFeature.feature.slug === PackageFeatures.DEVICES) {
      if (planHasFeature.feature.isSelected) {
        totalCost += Number(planHasFeature.price)
      }
    } else if (planHasFeature.feature.isSelected) {
      totalCost += Number(planHasFeature.price)
    }
  })
  totalCost += 250 * 0
  return totalCost
}

const getFeatureNameByEnum = enumValue => {
  const result = Object.keys(MemberPermissions)
    .find(key => MemberPermissions[key] === enumValue)
  if (result) {
    return result.replaceAll('_', ' ').toLowerCase()
  }
  return ''
}
const showToast = (title, text, variant = 'success', timeout = 2000) => {
  let icon = 'InfoIcon'
  if (variant === 'success') {
    icon = 'CheckCircleIcon'
  }
  if (variant === 'danger') {
    icon = 'AlertCircleIcon'
  }
  Vue.prototype.$toast({
    component: ToastificationContent,
    props: {
      icon,
      title,
      text,
      variant,
    },
  }, { timeout })
}
const showErrorMessage = statusCode => {
  if (statusCode === 401) {
    return false
  }
  return true
}
const test = () => {}
const confirmDeletion = async () => Vue.prototype.$swal({
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes, delete it!',
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-outline-danger ml-1',
  },
  buttonsStyling: false,
})

export {
  getFormDataFromJSON, test, getFeatureNameByEnum, showErrorMessage, showToast, confirmDeletion, packagePrice,
}
